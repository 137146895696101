'use strict';

import _firebase    from './_firebase.js';
import image        from './image.js';
import other        from './other.js';
import dataset      from './dataset.js';
import axios        from 'axios';

import {
    collection,
    doc,
    getDocs,
    query,
    where
} from 'firebase/firestore';

import { ref, getDownloadURL } from 'firebase/storage';

const serverURL         = 'https://vp1.com.rosepetal.ai/';
const serverURL_local   = 'http://localhost:3000/';

const vision = {

    getCsv: async function(datasetID, projectID) {
        if (!datasetID || !projectID) {
            throw new Error("datasetID and projectID are required");
        }
    
        try {
            const storage = _firebase.storage;
            const gsReference = ref(storage, `gs://${projectID}/model-config/${datasetID}.csv`);
            const url = await getDownloadURL(gsReference);
    
            if (url) {
                const response = await fetch(url);
                if (!response.ok) {
                    throw new Error(`Failed to fetch CSV: ${response.statusText}`);
                }
                return await response.text();
            } else {
                throw new Error(`Dataset ${datasetID} CSV not found in project ${projectID}`);
            }
        } catch (error) {
            return null;
        }
    },

    updateCsv: async function(opt = {}) {
        let csvQuery;

        if (opt.dataset.id) {
            csvQuery = 'api/dataset/' + opt.dataset.id + '/csv';

            if (opt.dataDivision.test || opt.dataDivision.validation) {
                csvQuery += opt.dataDivision.test ? "?test=" + opt.dataDivision.test : "";
                csvQuery += opt.dataDivision.validation && opt.dataDivision.test ? "&" : "";
                csvQuery += opt.dataDivision.validation ? "validation=" + opt.dataDivision.validation : "";
            }

            if (opt.tagMap) {
                if (opt.dataDivision.test || opt.dataDivision.validation) csvQuery  += '&tagmap=';
                else csvQuery  += '?tagmap=';
                Object.keys(opt.tagMap.tags).forEach(key => {
                    Object.keys(opt.tagMap.tags[key].tags).forEach(k => {
                        if (k != "count") csvQuery += key.replace(/\s/g, "!!-") + "-|-" + k.toString().replace(/\s/g, "!!-") + '--||--';
                    });
                });
            }

            console.log("CSV Query:", csvQuery);

            const csv = await other.httpsCallable(csvQuery);
            if (csv.data && csv.data.training && csv.data.training.dataset) {
                await dataset.update(opt.datasetID, { importCsv: csv.data.training.dataset });
                return csv;
            }
        }
    },

    uploadCsv: async function(csv, projectID, maxRetries = 5, delay = 1000) {
        let attempt = 0;
    
        while (attempt < maxRetries) {
            try {
                attempt++;
                const response = await axios.post(`${serverURL}uploadCsv`, { csv, projectID });
                return response.data;
            } catch (error) {
                if (attempt >= maxRetries) {
                    console.error("Maximum retries reached. Upload failed.");
                    throw new Error("Failed to upload CSV after multiple attempts.");
                }
    
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }
    },

    getCredentials: async function(opt = {}) {
        let credentials = await other.httpsCallable('api/model/' + opt.dataset.id + '/credentials');
        if (credentials.data && !credentials.data.error && credentials.data.result) {
            return credentials.data.result;
        }
    },

    getImages: async function (opt = {}) {
        const db = _firebase.firestore;

        const datasetID     = opt.dataset.id;
        const datasetType   = opt.dataset.type;
    
        if (!datasetID) return Promise.resolve([]);
    
        const datasetRef = doc(db, "dataset", datasetID);
        let imgsQuery = query(collection(db, "image"), where('dataset', '==', datasetRef));
        
        if (opt.missingImages && Array.isArray(opt.missingImages) && opt.missingImages.length > 0) {
            imgsQuery = query(collection(db, "image"), where('id', 'in', opt.missingImages));
        } else if (opt.tagMap) {
            let tagRefs = [];

            for (let key in opt.tagMap.tags) {
                const tags = opt.tagMap.tags[key].tags;
                for (let tag in tags) 
                    if (tag !== 'count') 
                        tagRefs.push(doc(db, "dataset", datasetID, "tag", tag));
            }

            if (datasetType == 'MULTICLASS') {
                imgsQuery = query(imgsQuery, where('tag', 'in', tagRefs));
            } else if (datasetType == 'MULTILABEL' || datasetType == 'imageObjectDetection') {
                imgsQuery = query(imgsQuery, where('tagsContained', 'array-contains-any', tagRefs));
            }
        }
    
        return getDocs(imgsQuery).then((imgsSnapshot) =>
            imgsSnapshot.docs.map((doc) => {
                const data = doc.data();
                data.id = doc.id;
                return data;
            })
        );
    },
    
    downloadImages: async function (img) {
        if (img.uri) {
            return image.getStorageUrl(img.uri)
                .then((storageUrl) => {
                    if (storageUrl.url) {
                        return fetch(storageUrl.url)
                            .then((response) => response.blob())
                            .then((blob) => Object.assign({}, img, { blob: blob }));
                    }
                    return null;
                })
                .catch((error) => {
                    console.error(`Error al descargar la imagen ${img.name}:`, error.message);
                    return null;
                });
        }

        return Promise.resolve(null);
    },

    sendImages: async function (img) {
        const formData = new FormData();

        if (img) {
            formData.append('name', img.name);
            formData.append('file', img.blob);
            formData.append('uri', img.uri);
        }

        return axios.post(serverURL + 'upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    },

    processImages: async function (images) {
        const concurrencyLimit = 20;
        let index = 0;
    
        const uploadPromises = [];
    
        const processNext = async () => {
            const currentIndex = index++;
            if (currentIndex >= images.length) {
                return;
            }
        
            const img = images[currentIndex];
            const downloadedImg = await this.downloadImages(img);
        
            if (downloadedImg) {
                const uploadPromise = this.sendImages(downloadedImg);
                uploadPromises.push({ promise: uploadPromise, index: currentIndex });
            }
        
            return processNext();
        };
        
        const workers = new Array(concurrencyLimit).fill(0).map(() => processNext());
        await Promise.all(workers);
        
        const uploadResults = await Promise.all(
            uploadPromises.map(async ({ promise, index }) => {
                const res = await promise.catch((error) => {
                    console.error(`Error uploading image ${index}:`, error.message);
                    return { status: 'error', data: null };
                });
                return { index, res };
            })
        );

        const formData = new FormData();
        formData.append('isLast', true);

        await axios.post(serverURL + 'upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        }).catch((error) => {
            console.error("Error sending final confirmation:", error.message);
        });
        
        return uploadResults.map(({ index, res }) => ({
            index,
            status: res.status || 'error',
            data: res.data || null,
        }));
    },

    manageRequest: async function (opt) {
        let resp = { status: "error", error: false };

        const datasetID = opt.dataset.id;
        const modelName = opt.modelName;
        const dataDivision = opt.dataDivision
        
        console.log("Updating CSV...");
        await this.updateCsv(opt);

        console.log("Getting credentials...");
        const credentials = await this.getCredentials(opt);

        try {
            const credentialsResponse = await axios.post(`${serverURL}uploadCredentials`, { datasetID, modelName, credentials, dataDivision });
            console.log("Server Response:", credentialsResponse.data);

            if (credentialsResponse.data.success) {
                resp.status = "success";
                return resp;
            } else {
                console.error("Server returned an error:", credentialsResponse.data.error);
            }
        } catch (error) {
            console.error("Error sending credentials:", error.message);
        }
    },

    renderEvaluations: async function(model, opt = false) {
        let resp = { status: "error", error: false, render: false };

        if (model) {
            let themeSettings = {
                dark: {
                    backgroundColor:    '#1a202c',
                    textColor:          '#fff',
                    matrixBackColor:    '#1a202c',
                    padBox:             '20px'
                },
                light: {
                    backgroundColor:    '#fff',
                    textColor:          '#1a202c',
                    matrixBackColor:    '#ededed',
                    padBox:             '0'
                },
                settings: {
                    textSize:   opt && opt.textSize ? opt.textSize : '0.75rem',
                    titleSize:  opt && opt.titleSize ? opt.titleSize : '15px',
                    titles:     opt && opt.titles ? opt.titles : true,
                }
            };

            resp.theme = opt && opt.theme ? opt.theme : 'dark';

            if (model.classes && model.confusion_matrix) {
                resp.render = "<div id='evaluationBox' style='color: " + themeSettings[resp.theme].textColor + "; background-color:" + themeSettings[resp.theme].backgroundColor + "; padding: " + themeSettings[resp.theme].padBox + "; font-size:" + themeSettings.settings.textSize + "'>"

                const classes           = Object.values(model.classes);;
                const confusion_matrix  = model.confusion_matrix;

                const dimension = classes.length;
                const matrix = [];
                for (let i = 0; i < dimension; i++) {
                    matrix[i] = confusion_matrix.slice(i * dimension, i * dimension + dimension);
                }

                const rowsPercent = [];
                for (let i = 0; i < dimension; i++) {
                    const rowSum = matrix[i].reduce((acc, val) => acc + val, 0);
                    rowsPercent[i] = matrix[i].map(val => {
                        if (rowSum === 0) return 0;
                        return ((val / rowSum) * 100).toFixed(0);
                    });
                }

                resp.render += "<table style='margin: 60px 10px 0 10px'>"
                resp.render += "<tr style='border-bottom: 1px solid #ccc;'>"
                resp.render += "<td style='padding:3px 0 0 0; min-width: 180px; max-width: 180px;'></td>"

                for (let j = 0; j < dimension; j++) {
                    resp.render += "<td style='padding:3px 5px;transform: translateX(-5%) translateY(-30px) rotate(-40deg) !important; overflow: hidden; overflow-x: visible; white-space: nowrap;min-width: 70px !important;max-width: 70px !important;'>"
                        + classes[j]
                        + "</td>"
                }

                resp.render += "</tr>"
                resp.render += "</table>"

                resp.render += "<table style='margin:5px 10px;'>"

                for (let i = 0; i < dimension; i++) {
                    resp.render += "<tr style='border-bottom: 1px solid #f7f8f9;line-height: 35px'>"
                    resp.render += "<td style='padding: 3px 0 0 0; min-width: 160px; max-width: 160px; overflow-x: hidden'>" + classes[i] + "</td>"

                    for (let j = 0; j < dimension; j++) {
                        const percent = rowsPercent[i][j];
                        let bgColor = themeSettings[resp.theme].matrixBackColor;
                        let textColor = themeSettings[resp.theme].textColor;

                        if (percent == 100) {
                            bgColor = '#104b9e';
                            textColor = '#fff';
                        } else if (percent > 50) {
                            bgColor = '#b7d3fa';
                            textColor = '#174EA6';
                        }

                        resp.render += "<td style='padding:3px 5px; max-width: 70px !important; width: 70px !important; min-width: 70px !important; text-align: center;"
                        resp.render += "background-color: " + bgColor + ";"
                        resp.render += "color: " + textColor + "'>"
                        resp.render += "<div style='padding: 0 0 0 5px; display: inline-block; line-height: 18px;'>"
                        if (percent > 0) resp.render += percent + "%"
                        else resp.render += "-"
                        resp.render += "</div>"
                        resp.render += "</td>"
                    }

                    resp.render += "</tr>"
                }

                resp.render += "</table>"

                resp.status = "success";
                resp.render += "</div>";
            } else { resp.error = "Model does not have evaluations"; }
        } else { resp.error = "Model is required"; }

        return resp.render;
    }
}

export default vision;